import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { ResumeCardData } from 'src/models/resumeCard';

const UPDATE_GIFTED_RESUME = 'UPDATE_GIFTED_RESUME';

interface GiftedResumes {
    resumes: ResumeCardData[];
}

interface PayloadTypes {
    [UPDATE_GIFTED_RESUME]: ResumeCardData;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const updateGiftedResume = actionCreator(UPDATE_GIFTED_RESUME);

export default createReducer<GiftedResumes, PayloadTypes>(
    { resumes: [] },
    {
        [UPDATE_GIFTED_RESUME]: (state, action) => {
            const idx = state.resumes.findIndex((resume) => resume._attributes.id === action.payload._attributes.id);

            if (idx < 0) {
                return state;
            }

            const resumes = [...state.resumes];
            resumes.splice(idx, 1, action.payload);

            return {
                ...state,
                resumes,
            };
        },
    }
);
